import React from "react";

export default function Bookbeat({ link }) {
    const basicLink = "https://click.adrecord.com/?c=44861&p=1281";
    return (
        <div className="externalLinkCard bg-gray-100">
            <h2 className="font-bold text-3xl text-gray-900 leading-tight mb-4">Bookbeat: Den bästa ljudbokstjänsten</h2>
            <p className="text-lg  text-gray-700 leading-tight mb-4">
               Bookbeat är den bästa och billigaste ljudbokstjänsten om du letar efter ett brett utbud av ljudböcker.
             Just nu får du dessutom prova på Bookbeat gratis i 30 dagar.
            </p>
            <a href={link || basicLink} target="_blank" rel="noopener noreferrer">
                <button className="BtnNextory rounded-xl">Testa Bookbeat i 30 dagar</button>
            </a>
        </div>
    );
}
