import React from "react";
import { graphql } from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image"
import { Link } from "gatsby";
import SEO from "../SEO";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import Navbar from "../Navbar/Navbar";
import Bookbeat from "../LinkCards/Bookbeat";
import Footer from "../Footer/Footer";

export default function BlogPost(props) {
  const options = { year: "numeric", month: "long", day: "numeric" };

  const post = props.data.markdownRemark;
  const url = "https://www.framgangspillret.com/" + props.path;
  const image = post.frontmatter.featuredImage?.childImageSharp.gatsbyImageData;
  const articletTitle =
    post.frontmatter.title +
    " av " +
    post.frontmatter.bookAuthor +
    " (sammanfattning)";
  return (
    <>
      <SEO
        title={articletTitle}
        description={post.frontmatter.description}
        article={true}
        image={image ? image.src : null}
      />
      <Navbar hideShadow={true} />
      <div className="container px-5 py-6 mx-auto">
        <div className="blogWrapper smallPageWidth">
          <article className="blogPost">
              {image && <GatsbyImage image={image} className="mb-5 rounded-xl" />}
              <h1 className="font-bold text-4xl md:text-5xl text-gray-900 leading-tight mb-4 mt-2">{articletTitle}</h1>
              <small className="text-md	font-medium">
                {post.frontmatter.author},{" "}
                {post.frontmatter.date &&
                  new Date(post.frontmatter.date).toLocaleDateString('se-SE', options)}
              </small>
              <br />
              <div dangerouslySetInnerHTML={{ __html: post.html }}  className="blogText" />
              <p>
                Vill du läsa fler boksammanfattningar, kolla in vår sida med{" "}
                <Link to={"/boktips/"}>boktips</Link>!
              </p>
              <div className="shareButtons">
                <FacebookShareButton url={url} className="share">
                  <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
                <TwitterShareButton
                  url={url}
                  title={post.frontmatter.title}
                  className="share"
                >
                  <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
              </div>
              <br />
              <Bookbeat />
              <br />
              <br />
          </article>
        </div>
      </div>
      <Footer dark={true} />
    </>
  );
}
export const query = graphql`
  query BookTipsQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        tag
        bookAuthor
        author
        description
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
